<template>
  <div class="container no-select" style="overflow-y: hidden">
    <el-row :gutter="20">
      <el-col :md="12" :lg="8" :xl="6" style="position: relative">
        <div class="card pb-0" style="position: relative">
          <el-form
            :inline="true"
            class="screen-form"
            :disabled="loading"
            @keyup.enter="search"
          >
            <el-form-item label="医院名称" class="pb-10" style="width: 100%">
              <el-input
                clearable
                v-model="form.name"
                placeholder="支持模糊搜索"
              ></el-input>
            </el-form-item>
            <el-form-item label="只看移植医院" class="pb-10">
              <el-switch v-model="form.cooperation"></el-switch>
            </el-form-item>
            <el-form-item label="只看已收录" class="pb-10">
              <el-switch v-model="form.included"></el-switch>
            </el-form-item>
          </el-form>
          <el-button
            class="search-btn"
            type="primary"
            :loading="loading"
            @click="search"
          >
            <el-icon v-if="!loading"><search /></el-icon>
            <span>查询</span>
          </el-button>
        </div>
        <div class="el-loading-spinner" v-if="loading">
          <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none"></circle>
          </svg>
        </div>
        <template v-else>
          <el-empty
            style="margin-top: calc(50vh - 240px)"
            description="未找到符合条件的医院"
            v-if="list.length === 0"
          ></el-empty>
          <template v-else>
            <el-scrollbar height="calc(100vh - 125px)">
              <div class="card" v-for="item in list" :key="item.mid">
                <div>
                  <div class="item-name line1">{{ item.name }}</div>
                  <div class="item-id">{{ item.id }}#{{ item.mid }}</div>
                  <div class="item-address line1 mt-5">
                    {{ item.province }}-{{ item.city }}-{{ item.district }} ·
                    {{ item.address }}
                  </div>
                </div>
                <el-button
                  class="float-right mt-5"
                  size="small"
                  @click="toMap(item.location)"
                >
                  <el-icon><map-location /></el-icon>
                  <span>在地图中查看</span></el-button
                >
                <div class="mt-5">
                  <template v-if="item.included">
                    <el-tag type="success" effect="dark">已收录</el-tag>
                    <el-button
                      v-if="item.cooperation"
                      class="ml-5"
                      size="small"
                      type="primary"
                      @click="switchCooperation(item.id, item.name, false)"
                    >
                      <span>移植医院</span>
                    </el-button>
                    <el-button
                      v-else
                      plain
                      size="small"
                      class="ml-5"
                      type="primary"
                      @click="switchCooperation(item.id, item.name, true)"
                    >
                      <el-icon><plus /></el-icon>
                      <span>移植医院</span>
                    </el-button>
                  </template>
                  <el-button
                    v-else
                    plain
                    size="small"
                    type="success"
                    @click="addHospital(item)"
                  >
                    <el-icon><plus /></el-icon>
                    <span>收录</span>
                  </el-button>
                </div>
              </div>
            </el-scrollbar>
          </template>
        </template>
      </el-col>
      <el-col :md="12" :lg="16" :xl="18">
        <div class="card baidu-map mb-0">
          <div class="map-mask">
            <div class="mb-10 mr-10">
              <el-button :disabled="zoom === 16" @click="editZoom(true)"
                >放大</el-button
              >
              <el-button :disabled="zoom === 11" @click="editZoom(false)"
                >缩小</el-button
              >
            </div>
          </div>
          <el-bmap
            ref="map"
            :lazy="500"
            :zoom="zoom"
            :center="center"
            :enableDragging="false"
            :enableKeyboard="false"
            :enableDoubleClickZoom="false"
            :enableScrollWheelZoom="false"
            :preserve-drawing-buffer="true"
            draggingCursor="default"
          >
            <el-bmap-marker
              :size="30"
              :icon="icon"
              :position="center"
              :offset="[-17, -30]"
            ></el-bmap-marker>
          </el-bmap>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Search, MapLocation, Plus } from "@element-plus/icons";
import { hospital } from "../../plugin/api";

export default {
  name: "Hospital",
  components: { Search, MapLocation, Plus },
  data: () => ({
    center: [113.927715, 22.559135],
    zoom: 16,
    list: [],
    form: {
      name: "",
      cooperation: false,
      included: true,
    },
    icon: {
      url: "/img/gps.png",
      imageOffset: [0, 0],
      size: [32, 32],
    },
    loading: true,
    number: 13,
    total: 0,
    page: 0,
  }),
  methods: {
    init() {
      let height = document.body.clientHeight;
      if (height > 768) this.number = 14 + parseInt((height - 768) / 40);
      this.getNumber();
    },
    editZoom(add) {
      let zoom = this.zoom;
      if (add && zoom <= 16) this.zoom = zoom + 1;
      else if (!add && zoom >= 11) this.zoom = zoom - 1;
    },
    search() {
      if (this.form.included) this.getNumber();
      else {
        if (this.form.name.trim() === "") {
          this.$alert(
            '当前未开启"只看已收录", 系统将在全国范围进行检索, 查询量较大, 所以必须提供"医院名称"才能继续',
            "缺少医院名称"
          );
        } else this.getList(1);
      }
    },
    getNumber() {
      this.loading = true;
      hospital
        .getNumber(this.form)
        .then((res) => {
          this.total = res.data;
          if (this.total > 0) this.getList(1);
          else {
            this.list = [];
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
          this.total = 0;
        });
    },
    getList(page) {
      this.page = page;
      this.loading = true;
      hospital
        .getList(this.form, page, this.number)
        .then((res) => {
          if (this.form.included)
            for (let i in res.data) res.data[i].included = true;
          setTimeout(() => {
            this.list = res.data;
            this.loading = false;
          }, 500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toMap(location) {
      this.center = location.split(",");
    },
    switchCooperation(id, name, state) {
      this.$confirm(
        '确认要将"' + name + '"' + (state ? "标为移植医院?" : "移出移植医院?"),
        state ? "标为移植医院" : "移出移植医院",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        hospital
          .updateCooperation(id, state)
          .then((res) => {
            if (res.state) {
              this.getList(this.page);
              this.$message.success("操作成功");
            } else this.$message.warning("操作失败");
          })
          .catch(() => {
            this.$message.error("网络错误");
          });
      });
    },
    addHospital(item) {
      this.$confirm(
        '确认要将"' + item.name + '"添加至系统医院库?',
        "添加入库",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        hospital
          .add(item)
          .then((res) => {
            if (res.state) {
              this.getList(this.page);
              this.$message.success("添加成功");
            } else this.$message.warning("添加失败");
          })
          .catch(() => {
            this.$message.error("网络错误");
          });
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.screen-form .el-form-item {
  margin: 0 10px 0 0;
}

.baidu-map {
  height: calc(100vh - 20px);
  position: relative;
  padding: 0;
  margin: 0;
}

.baidu-map .map-mask {
  background-color: rgb(255, 255, 255, 0.1);
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  display: flex;
  height: 100%;
  z-index: 10;
  width: 100%;
}

.baidu-map > div {
  border-radius: 8px;
  z-index: 9;
}

.search-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.item-name {
  font-size: 16px;
  font-weight: 500;
}

.item-id {
  font-size: 12px;
  color: #999;
}

.item-address {
  font-size: 14px;
}
</style>
